import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "../../Store";
import MainLayout from "../../Layouts/MainLayout";
import Input from "../../Components/Input";

import styles from "../../Styles/Forms.module.css";

function UsersCreation() {
  const navigate = useNavigate();
  const token: any = useSelector((state: RootState) => state.auth.token);
  const [name, setName] = useState<any>(null);
  const [email, setEmail] = useState<any>(null);
  const [password, setPassword] = useState<any>(null);
  const [role, setRole] = useState<any>("user");

  // const uploadData = async (e: any) => {
  //   e.preventDefault();
  //   let formData = new FormData();

  //   formData.append("cover", cover);
  //   formData.append("name", name);
  //   formData.append("audio", audio);
  //   formData.append("duration", duration);
    
  //   try {
  //     await axios.post("/users", formData, {
  //       headers: {
  //         Authorization: token
  //       }
  //     });

  //     navigate('/users');
  //   } catch (error: any) {
  //     console.error("Error: ", error);
  //     alert(error.response.user);
  //   }
  // };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      await axios.post("/users", {
        name,
        email,
        password,
        role
      }, {
        headers: {
          Authorization: token
        }
      });

      navigate('/users');
    } catch (error: any) {
      console.error("Error: ", error);
      alert(error.response.user);
    }
  }

  return (
    <MainLayout>
      <div className={styles.formContainer}>
        <header>
          <h1>Add User</h1>
        </header>
        <form onSubmit={handleSubmit}>
          <div className={styles.formControl}>
            <Input name="Name" state={name} setState={setName} required />
          </div>
          <div className={styles.formControl}>
            <Input type="email" name="Email" state={email} setState={setEmail} required />
          </div>
          <div className={styles.formControl}>
            <Input type="password" name="Password" state={password} setState={setPassword} required />
          </div>
          <div className={styles.formControl}>
            <Input type="select" name="Role" state={role} setState={setRole} options={[
              { name: "Admin", value: "admin" },
              { name: "User", value: "user" }
            ]} required />
          </div>
          <div className={styles.formControl}>
            <button className={styles.submitButton}>Add User</button>
          </div>
        </form>
      </div>
    </MainLayout>
  );
}

export default UsersCreation;
