import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { RootState } from "../Store";
import MainLayout from "../Layouts/MainLayout";
import Input from "../Components/Input";

import defaultCover from '../Images/default_music_icon.png';

import styles from "../Styles/Forms.module.css";

function SongAdd() {
  const navigate = useNavigate();
  const token = useSelector((state: RootState) => state.auth.token);
  const { id } = useParams();
  const [cover, setCover] = useState<File | null>(null);
  const [name, setName] = useState<string>('');
  const [artistName, setArtistName] = useState<string>('');
  const [albumName, setAlbumName] = useState<string>('');
  const [audio, setAudio] = useState<File | null>(null);

  // Handle file input changes
  const handleFileChange = (setState: React.Dispatch<React.SetStateAction<File | null>>) => 
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0] || null;
      setState(file);
    };

  const urlToFile = async (url: string, filename: string): Promise<File> => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return new File([blob], filename, { type: 'image/png' }); // Explicitly set type for PNG
    } catch (error) {
      console.error('Error converting URL to file:', error);
      throw error;
    }
  };

  useEffect(() => {
    const setDefaultCover = async () => {
      try {
        const defaultCoverFile = await urlToFile(defaultCover, 'default_music_icon.png');
        // Only set default cover if no cover is currently selected
        if (!cover) {
          setCover(defaultCoverFile);
        }
      } catch (error) {
        console.error('Error setting default cover:', error);
      }
    };

    setDefaultCover();
  }, []); // Remove cover from dependencies to prevent infinite loop

  const uploadData = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    // Validate required fields
    if (!name || !artistName || !audio) {
      alert('Please fill in all required fields (Song Name, Artist Name, and Audio)');
      return;
    }

    const formData = new FormData();

    if (cover) {
      formData.append("cover", cover);
    }
    formData.append("name", name.trim());
    formData.append("artistName", artistName.trim());
    formData.append("albumName", albumName.trim() || "N/A");
    if (audio) {
      formData.append("audio", audio);
    }
    if (id) {
      formData.append("playlistId", id);
    }
    
    try {
      await axios.post("/songs", formData, {
        headers: {
          Authorization: token, // Added Bearer prefix
          'Content-Type': 'multipart/form-data'
        }
      });

      navigate(-1);
    } catch (error: any) {
      console.error("Error: ", error);
      alert(error.response?.data?.message || 'Failed to upload song. Please try again.');
    }
  };

  return (
    <MainLayout>
      <div className={styles.formContainer}>
        <header>
          <h1>Add Song</h1>
        </header>
        <form onSubmit={uploadData}>
          <div className={styles.formControl}>
            <Input 
              type="file" 
              name="Cover" 
              accept="image/*"
              onChange={handleFileChange(setCover)}
            />
          </div>
          <div className={styles.formControl}>
            <Input 
              name="Song Name" 
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
              required
            />
          </div>
          <div className={styles.formControl}>
            <Input 
              name="Artist Name" 
              value={artistName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setArtistName(e.target.value)}
              required
            />
          </div>
          <div className={styles.formControl}>
            <Input 
              name="Album Name" 
              value={albumName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAlbumName(e.target.value)}
            />
          </div>
          <div className={styles.formControl}>
            <Input 
              type="file" 
              name="Audio" 
              accept="audio/*"
              onChange={handleFileChange(setAudio)}
              required
            />
          </div>
          <div className={styles.formControl}>
            <button type="submit" className={styles.submitButton}>Add Song</button>
          </div>
        </form>
      </div>
    </MainLayout>
  );
}

export default SongAdd;
