import { useRef } from "react";
import styles from "../Styles/Input.module.css";

interface SelectOption {
  value: string | number;
  name: string;
}

type InputChangeEvent = React.ChangeEvent<HTMLInputElement>;
type SelectChangeEvent = React.ChangeEvent<HTMLSelectElement>;

interface InputProps {
  name: string;
  type?: 'text' | 'file' | 'select' | 'datetime-local' | 'password' | 'email' | 'number';
  state?: string | File | null;
  setState?: (value: any) => void;
  options?: SelectOption[];
  required?: boolean;
  accept?: string;
  onChange?: any;
  [key: string]: any;
}

function Input({ 
  name, 
  state = "", 
  setState = () => {}, 
  type = "text", 
  onChange,
  ...props
}: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (e: InputChangeEvent) => {
    if (onChange) {
      onChange(e);
      return;
    }

    if (type === 'file') {
      setState(e.target.files?.[0] || null);
    } else {
      setState(e.target.value);
    }
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    if (onChange) {
      onChange(e);
      return;
    }
    setState(e.target.value);
  };

  return (
    <div className={styles.formInput}>
      <label>{name}</label>
      {type === "select" ? (
        <select
          value={state as string}
          onChange={handleSelectChange}
          {...props}
        >
          {props?.options?.map((option: SelectOption) => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.name}
            </option>
          ))}
        </select>
      ) : type === "datetime-local" ? (
        <div style={{ position: "relative", display: "inline-block" }}>
          <input
            ref={inputRef}
            value={state as string}
            onChange={handleInputChange}
            type={type}
            {...props}
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              cursor: "pointer"
            }}
            onClick={() => inputRef.current?.showPicker()}
          ></div>
        </div>
      ) : type === "file" ? (
        <input 
          type="file"
          onChange={handleInputChange}
          {...props}
        />
      ) : (
        <input
          value={state as string}
          onChange={handleInputChange}
          type={type}
          {...props}
        />
      )}
    </div>
  );
}

export default Input;